html,
body {
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.drawer-navTopMobile {
  z-index: 9999 !important;
}
.drawer-navTopMobile .MuiBackdrop-root {
  background-color: transparent;
}

.hide-element.hide-element {
  opacity: 0;
  transition: opacity 4s ease-in-out;
}

.sara {
  background-color: blueviolet !important;
}

#basic-menu-card-travelog .MuiPaper-root {
  margin-top: 9px;
  background-color: transparent;
}
#basic-menu-card-travelog .MuiMenuItem-root:hover {
  background-color: #423e60;
}
#basic-menu-card-travelog .MuiList-root {
  background-color: #2b2841;
  padding: 0;
}
#basic-menu-card-travelog .MuiMenuItem-root {
  min-height: auto !important;
}
